<template>
    <div>
        <!-- <h1>index</h1> -->
        <div class="content">
            <img src="../../assets/searchlogo.png" alt="">
            <h3>中检质量区块链</h3>
            <el-input v-model="index" placeholder="请输入标签上的追溯码" class="index"></el-input>
            <el-button @click="handleConfirm" type="primary" class="index butn">确认</el-button>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return{
            index:null
        }
    },
    methods:{
        handleConfirm(){
            // console.log(this.index,'查询接口');
            this.$axios({
                url:'/show/findRetrospect',
                method:'get',
                params:{
                    indexNo:this.index
                }
            }).then((result) => {
                // console.log(result);
                if(result.data.retCode==='9999'){
                    // this.$message.error(result.data.retMsg)
                    this.$router.push({
                        path:'/info',
                        query:{
                            no:this.index
                        }
                    })
                    return false 
                }else{
                    localStorage.setItem('batchId',result.data.retData)
                    localStorage.setItem('indexNo',this.index)
                    this.$router.push('/')
                }
                this
            }).catch((err) => {
                console.log(err);
            });
        }
    }
}
</script>
<style lang="scss" scoped>
.content{
    padding: 0 15px;
    height: 400px;
    // background-color: antiquewhite;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.index{
    margin-top: 15px;
    width: 80%;
}
.butn{
    width: 80%;
    border-radius: 20px;
}
</style>